<template>
  <div class="category-slider">
    <carousel-3d
      v-if="categories"
      :disable3d="true"
      :space="200"
      :clickable="false"
      :controls-visible="true"
      :width="180"
      :height="240"
      :display="7"
    >
      <slide
        v-for="(category, i) in categories"
        :key="i"
        :index="i"
      >
        <router-link :to="`/category/${category.slug}`">
          <progressive-img
            v-b-popover.hover.bottom="''"
            :title="category.name"
            :src="`https://images.igdb.com/igdb/image/upload/t_cover_big/${category.cover_image_id}.png`"
            fallback="https://f004.backblazeb2.com/file/brime-assets/missing_cover.png"
          />
          <img :src="``">
        </router-link>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { Carousel3d, Slide } from 'vue-carousel-3d'
import { VBPopover } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    Carousel3d,
    Slide,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      categories: null,
    }
  },
  async created() {
    function unique(arr) {
      const xids = []
      return arr.filter(e => {
        if (xids.includes(e.slug)) return false
        xids.push(e.slug)
        return true
      })
    }
    const categories = await this.getLiveCategories()
    this.categories = unique(categories)
  },
}
</script>

    <style>
    .category-slider {
    padding-bottom: 30px;
    }
    </style>
    <style scoped>
    img {
    width: 100%;
    }
    </style>
